.aonWrapper {
  position: relative;
}
.sidebarHolder {
  width: 250px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  transition: all 0.2s ease;
}
.sidebarWrap {
  width: 250px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  overflow: auto;
  background-color: #ffffff;
  z-index: 1;
}
.mainBodyWrap {
  width: calc(100% - 250px);
  margin-left: 250px;
}
.headerWrap {
  height: 100px;
  background: linear-gradient(
      268.7deg,
      #251b8f 9.68%,
      rgba(7, 44, 116, 0) 64.26%
    ),
    #1a50ba;
  display: flex;
  align-items: center;
}
.pageInner {
  margin-top: 20px;
}
.bodyWrap {
  padding: 30px 30px;
  background: rgba(186, 186, 186, 0.39);
  height: calc(100vh - 100px);
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2);
  position: relative;
}
.bodyWrap::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
.bodyWrap::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 30px;
  scrollbar-color: rgba(0, 0, 0, 0.2);
}
a,
button {
  transition: all 0.2s ease;
  text-decoration: none;
}

/* btn css */
.btnCustom {
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  transition: all 0.2s ease;
  padding: 15px 30px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  min-width: 150px;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
}
.btnSmall {
  border-radius: 5px;
  padding:5px 10px;
  min-width: none;
}
.btnCustom img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 10px;
}
.btnYellow {
  background: #ffc200;
  box-shadow: 0px 4px 4px rgba(255, 195, 0, 0.2);
}
.btnYellow:hover {
  background: #e2ad00;
}
.btnYellowGradient {
  background: linear-gradient(
    92.23deg,
    #ffc300 16.73%,
    rgba(255, 195, 0, 0.63) 90.97%
  );
}
.btnYellowGradient:hover {
  opacity: 0.8;
}

.btnBig {
  min-width: 250px;
}
.smallUtlBtn {
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  transition: all 0.2s ease;
  padding: 10px 15px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.smallUtlBtn img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.btnGrey {
  background: #7e7d7d;
}
.btnGrey:hover {
  background: #636363;
}
.btnfadeBlue {
  background: #687582;
  box-shadow: 0px 4px 4px rgba(104, 117, 130, 0.15);
}
.btnfadeBlue:hover {
  background: #505d69;
}
.smUtlBtnWrap {
  margin-left: 10px;
}
.smUtlBtnWrap a,
.smUtlBtnWrap button {
  margin-left: 10px;
}
.btnWrap {
  display: flex;
  align-items: center;
}
.btnWrap a,
.btnWrap button {
  margin-right: 20px;
}
.btnWrap a:last-child,
.btnWrap button:last-child {
  margin-right: 0;
}

.sectionHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 15px; */
  margin-bottom: 20px;
}
.sectionHeading {
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  letter-spacing: 0.045em;
  color: #000000;
  margin: 0;
}
.linkMapWrap {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.linkMapWrap a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.045em;
  color: #989898;
  margin-right: 15px;
}
.linkMapWrap a:hover {
  color: #616161;
}
.btnBordered {
  border: 1px solid #2f2e2e;
  box-shadow: 1px 5px 4px -4px rgba(47, 46, 46, 0.15);
  background: transparent;
  color: #2f2e2e;
}
.btnBordered:hover {
  background-color: #fff;
}

.btnBorderedYellow {
  border: 1px solid #ffc300;
  background: transparent;
  color: #ffc300;
}
.btnBorderedYellow:hover {
  background-color: #fff9e4;
}

.btnHolder a,
.btnHolder .button {
  margin-right: 20px;
}
.btnHolder a:last-child,
.btnHolder button:last-child {
  margin-right: 0;
}
.btnRed {
  background: #d60c0c;
  box-shadow: 0px 4px 4px rgba(214, 12, 12, 0.15);
}
.btnRed:hover {
  background: #a30505;
}
.btnBlue {
  background-color: #1a50ba;
  box-shadow: 0px 4px 4px rgba(26, 80, 186, 0.2);
}
.btnBlue:hover {
  background-color: #0e388a;
}
.btnBlack {
  background-color: #2f2e2e;
  box-shadow: 0px 4px 4px rgba(104, 117, 130, 0.15);
}
.btnBlack:hover {
  background-color: #000000;
}
.btnGreen {
  background: #27bf69;
  box-shadow: 0px 4px 4px rgba(39, 191, 105, 0.2);
}
.btnDisabled {
  background: #cccccc;
}
.btnGreen:hover {
  background: #1b914e;
}
.flex-justify-end {
  display: flex;
  justify-content: flex-end;
}
.flex-justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-beween-cennter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.customSelect {
  background: linear-gradient(
    171.45deg,
    #b8b8b8 6.53%,
    rgb(84 79 79 / 18%) 120.7%
  );
  padding: 5px 10px;
  border-radius: 5px;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  border: none;
}
.customSelect:hover{cursor: pointer;}
.customSelect:focus {
  outline: none;
}
.m-l-10 {
  margin-left: 10px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.customTable {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
.customTable th {
  padding: 15px 15px;
  background-color: #f7f7f7;
  font-size: 18px;
  color: #4a4a4a;
  font-weight: 600;
}
.customTable tr {
  border-bottom: 1px solid rgba(152, 152, 152, 0.27);
}
.customTable thead tr {
  border-bottom: 0;
}
.customTable tr:hover td {
  background-color: rgba(26, 79, 186, 0.06);
}
.customTable th:first-child,
tr:first-child {
  border-radius: 8px 0 0 8px;
}
.customTable th:last-child,
tr:first-child {
  border-radius: 0 8px 8px 0;
}
.customTable td {
  padding: 15px 15px;
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 400;
}
.checkboxCol {
  width: 50px;
}
.tblCheckbox {
  margin-right: 10px;
}

.tableCard {
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-bottom: 30px;
}

.faddedTxt {
  opacity: 0.6;
}
.tableHeadWrap {
  padding: 15px 30px;
}
.shortIcon {
  width: 22px !important;
  height: 22px !important;
  object-fit: contain;
}

/* tag styling */

.statusTag {
  font-size: 14px;
  font-weight: 600;
  border-radius: 30px;
  padding: 5px 15px;
  min-width: 110px;
  display: inline-block;
}
.statusTag.yellow {
  background-color: rgba(229, 138, 0, 0.27);
  color: #c27400;
}
.statusTag.green {
  background-color: rgba(39, 191, 105, 0.27);
  color: #27bf69;
}
.statusTag.grey {
  background-color: #d9d9d9;
  color: #737272;
}
.statusTag.red {
  background-color: rgba(222, 0, 0, 0.27);
  color: #e70000;
}
.noDatatxt {
  align-items: center;
  text-align: center;
  letter-spacing: 0.045em;
  color: #d1d1d1;
  font-size: 18px;
  font-weight: 600;
}
.tblIconUtlWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tblUtlIcon {
  background: transparent;
  border: none;
  outline: none;
  margin-right: 3px;
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 20px;
  background-color: transparent;
}
.tblUtlIcon:hover {
  background-color: #e1e9f7;
}
.tblUtlIcon:last-child {
  margin-right: 0;
}
.tblUtlIcon img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

/* Tab styling */

.tabTitle {
  margin:10px;
}

.tabBodyItem {
  margin-bottom: 30px;
}
.tabHead {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(152, 152, 152, 0.2);
}
.tabBody {
  padding: 20px;
}
.tabHeadItem {
  margin-right: 10px;
  padding: 20px 50px;
  color: #687582;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}
.tabHeadItem::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 20px 20px 0 0;
  background-color: transparent;
  transition: all 0.2s ease;
}
.tabHeadItem.active {
  color: #1a50ba;
}
.tabHeadItem:hover {
  color: #1a50ba;
}
.tabHeadItem.active::after {
  background-color: #1a50ba;
}
.weight300 {
  font-weight: 300 !important;
}
.bigBadge {
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  padding: 15px 40px;
  color: #ffffff;
}
.badgeBlue {
  background: linear-gradient(
    266.66deg,
    rgba(0, 77, 228, 0.87) 47.24%,
    #1a50ba 100%
  );
}
.bigBadge img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 20px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-t-100 {
  margin-top: 100px;
}
.alertTop {
  margin-bottom: 50px;
}
.alertImg {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-right: 15px;
}
.alertTxt {
  font-weight: 400;
  font-size: 30px;
  line-height: 140%;
  color: #2f2e2e;
}

.globalCard {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 20px;
}
.borderedBox {
  border: 1px solid rgba(152, 152, 152, 0.21);
  border-radius: 8px;
  background-color: #fff;
}
.modalHeadTransp {
  padding: 20px 25px;
  background-color: transparent;
  border-bottom: 1px solid rgba(140, 140, 140, 0.5);
}
.modalheader {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #2f2e2e;
  margin: 0;
}
.txtRed {
  color: #ff0000;
}
.borderInp {
  background: #ffffff;
  border: 1px solid rgba(152, 152, 152, 0.74);
  border-radius: 8px;
  width: 100%;
  background-color: #fff;
  color: #000000;
  padding: 15px 15px;
}
.borderInp:focus {
  outline: none;
}
.borderInpSm {
  background: #ffffff;
  border: 1px solid rgba(152, 152, 152, 0.74);
  border-radius: 8px;
  width:50px;
  background-color: #fff;
  color: #000000;
  padding: 5px 5px;
}
.borderInpSm:focus {
  outline: none;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.inplabel {
  font-weight: 400;
  font-size: 16px;
  line-height: 128.68%;
  color: #7e7d7d;
  margin-bottom: 15px;
  display: block;
}
.width70 {
  width: 70%;
}
.widthAuto {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.reviewSessionWrap {
  text-align: center;
}
.tableWrap {
  overflow-x: auto;
}
.modalWrapper {
  max-width: 95%;
}
.tblZipIcon {
  width: 25px;
}
.tblZipIcon:hover{cursor: pointer;}
.downloadIconLink {
  display: inline-block;
}
.tblDwnlIcon {
  width: 20px;
}
.iconCol {
  width: 50px;
}
[data-column-id="iconZip"].rdt_TableCol {
  width: 60px;
  flex-grow: 0;
  min-width: 60px;
}
[data-column-id="iconZip"].rdt_TableCell {
  width: 60px;
  flex-grow: 0;
  min-width: 60px;
}
.rdt_TableHeadRow {
  background-color: #f7f7f7 !important;
}
.sidebarSmOverlay {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.width70p {
  width: 70%;
  display: block;
  margin: 0 auto;
}
.greyTxt {
  color: #585656 !important;
}

/* L U css start */

.gap10 {
  height: 10px;
  display: block;
}
.gap20 {
  height: 20px;
  display: block;
}
.gap30 {
  height: 30px;
  display: block;
}
.gap40 {
  height: 40px;
  display: block;
}
.gap50 {
  height: 50px;
  display: block;
}
.padding-0 {
  padding: 0 !important;
}

.btnCustom.btnOrange {
  background: #ff6b00;
  box-shadow: 0px 4px 4px rgba(104, 117, 130, 0.25);
  cursor: pointer;
}
.btnCustom.btnOrange:hover {
  background: #e76101;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-r-10{
  margin-right:10px;
}
.text-right {
  text-align: right;
}
.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.align-top {
  align-items: flex-start !important;
}
.customRow {
  width: 100%;
  height: initial;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.customColumn-12 {
  width: 100%;
}
.customColumn-8 {
  width: 65.66%;
}
.customColumn-7 {
  width: 57.33%;
}
.customColumn-6 {
  width: 49%;
}
.customColumn-5 {
  width: 40.66%;
}
.customColumn-4 {
  width: 32.33%;
}
.customColumn-3 {
  width: 23.5%;
}
.customColumn-2 {
  width: 15%;
}
.cardWhite {
  width: 100%;
  height: initial;
  background-color: #fff;
  margin: 15px 0;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.cardWhiteTitleWrap {
  width: 100%;
  padding: 20px 40px;
  border-bottom: solid 1px rgba(152, 152, 152, 0.28);
}
.cardWhiteHeading {
  font-size: 20px;
  font-weight: 700;
  color: rgba(47, 46, 46, 0.65);
  line-height: 120%;
  margin: 0;
}
.cardWhiteBodyWrap {
  width: 100%;
  padding: 20px 40px;
  border-bottom: solid 1px rgba(152, 152, 152, 0.28);
  min-height: 350px;
}
.formLabel {
  font-size: 16px;
  color: #585656;
  line-height: 120%;
  font-weight: 700;
  margin: 0;
  position: relative;
}
.formLabel span {
  font-size: 20px;
  color: #d60c0c;
  line-height: 20px;
  position: absolute;
  top: 0px;
  right: -15px;
}
input.inputText {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 0.3px solid #687582;
  border-radius: 5px;
  color: #2f2e2e;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  padding: 0px 15px;
  margin: 0;
}
input.inputText:focus {
  border: 0.3px solid rgba(26, 80, 186, 1);
  outline: transparent;
}

input.inputTextfocus {
  background-color: #ececec;
}

textarea.textArea {
  width: 100%;
  background: #ffffff;
  border: 0.3px solid #687582 !important;
  border-radius: 5px;
  color: #2f2e2e;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  padding: 0px 15px;
  margin: 0;
}
textarea.textArea:focus {
  border: 0.3px solid rgba(26, 80, 186, 1);
  outline: transparent;
}

textarea.textAreafocus {
  background-color: #ececec;
}
select.selectWrap {
  width: 100%;
  height: 40px;
  background-image: linear-gradient(#bebebe, rgb(212 212 212 / 62%));
  border: 0.3px solid #687582;
  border-radius: 5px;
  color: #2f2e2e;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  padding: 0px 15px;
  margin: 0;
  position: relative;
  z-index: 0;
}

select.selectWrap:focus {
  outline: transparent;
}
select.selectWrap option {
  width: 100%;
  background-color: #bebebe;
  color: #2f2e2e;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  padding: 15px 15px !important;
  margin: 15px 0 !important;
}
.formIcon {
  width: initial;
  height: 40px;
}
.formIcon img {
  width: initial;
  height: 100%;
}
.sectionTitle {
  padding-left: 40px;
}

input.inputTextModal {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: 0.5px solid rgba(104, 117, 130, 0.5);
  border-radius: 5px;
  color: #2f2e2e;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  padding: 0px 15px;
  margin: 0;
}
input.inputTextModal:focus {
  border: 0.3px solid rgba(26, 80, 186, 1);
  outline: transparent;
}
input.inputTextModal::placeholder {
  color: rgba(47, 46, 46, 0.42);
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
}

/* modal */

.modalOverlay {
  position: fixed;
  top: 100px;
  left: 250px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 990;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalWrapper {
  width: 600px;
  border-radius: 5px;
  overflow: hidden;
}

.modalWrapperLarge {
  width: 800px;
  border-radius: 5px;
  overflow: hidden;
}
.modalWrapper.bgWhite {
  background-color: #f6f8fa;
}
.modalWrapperHeader {
  width: 100%;
  height: 60px;
  text-align: center;
  background-color: #1a50ba;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  line-height: 60px;
}
.modalWrapperBody {
  background-color: #f6f8fa;
  padding: 20px;
  width: 100%;
  max-height: 90vh;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2);
}
.modalWrapperBody::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
.modalWrapperBody::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: rgba(0, 0, 0, 0.2);
}
.modalContWrapper {
  width: 100%;
  border: 0.5px solid rgba(104, 117, 130, 0.5);
  border-radius: 5px;
  padding: 20px;
  position: relative;
}
.modalContWrapper h2 {
  color: #2f2e2e;
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  margin-bottom: 20px;
}
.modalContWrapper p {
  color: #2f2e2e;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 20px;
}
.modalContWrapper p:last-child {
  margin-bottom: 0px;
}
.modalButton {
  padding: 15px 70px;
}
.contWrapperLabel {
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
  text-align: center;
}
.contWrapperLabel span {
  background-color: #f6f8fa;
  padding: 5px 15px;
  color: #687582;
  font-size: 16px;
  font-weight: 400;
}
.radioWrapper {
  width: 100%;
}
.radioWrapper input {
  width: 15px;
  height: 15px;
}
.radioWrapper label {
  font-size: 16px;
  color: rgba(47, 46, 46, 1);
  line-height: 120%;
  font-weight: 700;
  margin: 0;
  margin-right: 50px;
}
.radioWrapper label:last-child {
  margin-right: 0px;
}
.buttonIconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.buttonIconWrap button {
  margin: 0 15px;
  width: 60px;
  height: 60px;
  background-color: transparent;
  cursor: pointer;
  border: 0;
}
.buttonIconWrap button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* color modals*/

.deleteModalBg {
  background-image: linear-gradient(#ff9790, rgb(255 255 255 / 62%));
}
.downloadModalBg {
  background-image: linear-gradient(#5effa4, rgb(255 255 255 / 62%));
}
.confirmModalBg {
  background-image: linear-gradient(#ffac71, rgb(255 255 255 / 62%));
}
.errorModalBg {
  background-image: linear-gradient(#ffc300, rgb(255 255 255 / 62%));
}
.customeModalWrapper {
  width: 600px;
  border-radius: 10px;
  background-color: #fff;
  padding: 30px;
}
.customeModalWrapperBody {
  width: 100%;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customeModalContent {
  text-align: center;
}
.customeModalContent img {
  height: 100px;
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}
.customeModalContent span {
  font-size: 30px;
  color: #7e7d7d;
  font-weight: 400;
  line-height: 140%;
}
.customeModalWrapperFooter {
  width: 100%;
  padding: 30px 0px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 0.6px solid rgba(126, 125, 125, 0.33);
}

/* auth page */

.authPageWrap {
  width: 100%;
  min-height: 100vh;
  background: #f4f4f4;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.authFormLeft {
  width: 35%;
}
.authImgRight {
  width: 65%;
}
@media (max-width: 1600px) {
  .authFormLeft {
    width: 40%;
  }
  .authImgRight {
    width: 60%;
  }
}
.authImageWrap {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
.authImageWrap img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.authFormWrapper {
  width: 100%;
  padding: 50px;
}
.authLogo {
  display: block;
  height: 40px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 50px;
}
.authLogo img {
  width: 100%;
}
.font-bold {
  font-weight: bold;
}
.authInput {
  border: 0.4px solid rgba(124, 125, 128, 0.43) !important;
  height: 50px !important;
  margin-bottom: 10px !important;
}
.authInput::placeholder {
  color: rgba(52, 52, 52, 49%);
  font-weight: 400;
}
.authButton {
  padding: 20px 150px;
  font-size: 22px;
  box-shadow: none !important;
}
.authButtonBorder {
  padding: 20px 100px;
  font-size: 22px;
  box-shadow: none !important;
}
.authTerms {
  padding-left: 50px;
  text-align: left;
  display: flex;
}
.authTerms input {
  width: 18px;
  height: 18px;
  border-radius: 6px;
  margin-right: 15px;
}
.authTerms label {
  font-size: 16px;
}
.authTerms a {
  font-size: 16px;
  color: #1a50ba;
  margin: 0 5px;
}
.forgetLabel {
  font-size: 20px;
  color: #2a4f75;
  margin: 20px auto 30px;
  font-weight: 700;
  border-bottom: 0.5px solid rgba(109, 125, 148, 0.56);
  padding-bottom: 30px;
  display: block;
  width: 80%;
  text-align: center;
}
.passwordDiv {
  position: relative;
}
.passwordDiv input {
  padding-right: 50px;
}
.passwordView {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.passwordView img {
  width: 25px;
  opacity: 0.5;
}
.registrationHead {
  font-size: 40px;
  color: #2a4f75;
}
.regdLoginDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.regdLoginDiv span {
  font-size: 16px;
  color: #2f2e2e;
  margin-right: 5px;
}
.regdLoginDiv a {
  font-size: 16px;
  font-weight: bold;
  color: #1a50ba;
}
.p-t-40 {
  padding-top: 40px;
}
.authCreateWrapper {
  max-width: 800px;
  margin: 0 auto;
}
.authCreateWrapper .authFormWrapper {
  padding: 20px;
}
.p-l-0 {
  padding-left: 0px;
}
.authTopLogo {
  height: 100px;
  margin: 0 auto;
  margin-bottom: 50px;
  display: block;
}
.registrationSubhead {
  display: block;
  text-align: center;
  font-size: 26px;
  color: #585656;
  font-weight: 500;
  margin-top: 10px;
}
.m-b-0 {
  margin-bottom: 0;
}
.customColumn500 {
  max-width: 500px;
  margin: 40px auto 0;
}
.backPageLink a {
  font-size: 18px;
  color: #585656;
  font-weight: 700;
}
.backPageLink a img {
  width: 20px;
  margin-right: 10px;
}
.m-0 {
  margin: 0 auto;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* this creates three equal-width columns */
  gap: 10px; /* optional: adds space between grid items */
}

.grid-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* this creates three equal-width columns */
  gap: 10px; /* optional: adds space between grid items */
}

hr { border: .2px solid #ebeaea;}
/* L U css end */

/* =========== */

/* responsive css */
@media (max-width: 1367px) {
  .tabHeadItem {
    padding: 20px 20px;
  }
}
@media (max-width: 1100px) {
  .tabHeadItem {
    padding: 15px 10px;
    font-size: 14px;
  }
  .grid-5 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* this creates three equal-width columns */
    gap: 10px; /* optional: adds space between grid items */
  }
}

@media (max-width: 768px) {
  .grid-5 {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* this creates three equal-width columns */
    gap: 10px; /* optional: adds space between grid items */
  }
  .sidebarHolder {
    position: absolute;
    left: -100%;
    width: 100%;
    transition: all 0.2s ease;
  }
  .sidebarWrap {
    position: absolute;
    background-color: #fff;
    z-index: 999;
    /* left: -260px; */
    left: 0;
  }
  .sidebarHolder.expand {
    transition: all 0.2s ease;
    left: 0 !important;
  }
  .mainBodyWrap {
    width: 100%;
    margin-left: 0;
  }
  .modalOverlay {
    top: 0;
    left: 0;
  }
  .authFormLeft {
    width: 100%;
  }
  .authImgRight {
    display: none;
  }
}

@media (max-width: 767px) {
  .width70 {
    width: 100%;
  }
  .headerWrap {
    height: 80px;
  }
  .bodyWrap {
    padding: 15px 15px;
  }
  .sectionHead {
    flex-direction: column;
    align-items: flex-start;
  }
  .sectionHead .flex-align-center {
    margin-bottom: 10px;
  }
  .sectionHeading {
    font-size: 20px;
  }
  .btnCustom {
    min-width: 100px;
    padding: 12px 15px;
    font-size: 14px;
  }
  .btnHolder a,
  .btnHolder button {
    margin-right: 15px;
  }
  .btnCustom img {
    width: 15px;
    height: 15px;
  }
  .alertImg {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
  .alertTxt {
    font-weight: 400;
    font-size: 22px;
    color: #2f2e2e;
  }
  .m-t-100 {
    margin-top: 50px;
  }
  .m-t-20{ margin-top:20px;}
  .statusbtnWrap {
    flex-direction: column;
  }
  .statusbtnWrap .btnCustom:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .bigBadge {
    padding: 15px 20px;
  }
  .bigBadge img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .tabBody {
    padding: 10px;
  }
  .tabHead {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
  }
  .tabHeadItem {
    padding: 15px 8px;
    font-size: 13px;
    white-space: nowrap;
  }
  .customTable th {
    font-size: 16px;
    padding: 10px 10px;
  }
  .customTable td {
    padding: 10px 10px;
    font-size: 14px;
  }
  .customColumn-6 {
    width: 100%;
  }
  .customColumn-4 {
    width: 100%;
  }
  .customColumn-8 {
    width: 100%;
  }
  .formLabel {
    margin-bottom: 12px;
  }
  .cardWhiteTitleWrap {
    padding: 20px 30px;
  }
  .cardWhiteBodyWrap {
    padding: 20px 30px;
  }
  .authFormWrapper {
    padding: 30px;
  }
  .authLogo {
    height: 30px;
    margin-bottom: 30px;
  }
  .registrationHead {
    font-size: 32px;
  }
  .authTerms {
    flex-direction: column;
    padding-left: 0;
  }
  .authTerms div {
    margin-bottom: 10px;
  }
  .authCreateWrapper {
    padding: 0 30px;
  }
  .authCreateWrapper .authFormWrapper {
    padding: 0px;
  }
  .authPageWrap {
    height: auto;
  }
  .authTopLogo {
    margin-bottom: 30px;
  }
  .registrationSubhead {
    font-size: 20px;
  }
  .customColumn500 {
    margin: 30px auto 0;
  }
  .width70p {
    width: 100%;
  }
}

.hidden {
  display: none;
}

/* progressbar styling */

.prgrsWrap {
  text-align: center;
}
.prgTxt {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  margin: 0 0 20px;
}
.prgChartWrap {
  display: block;
  margin: 0 auto;
}
.prgHolder {
  text-align: center;
  padding-top: 50px;
}

/* spinner loader styling */
.spinnerWrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
}
.spinnerText {
  color: #fff;
  font-size: 24px;
  margin: 0 0 20px;
}
.spinnerImg {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

/* slider css */

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 103% !important;
  display: block;
  width: 35px !important;
  height: 35px !important;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 50% !important;
  z-index: 100;
}
.slick-prev {
  left: 35% !important;
}
.slick-next {
  right: 35% !important;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 35px !important;
  line-height: 1;
  opacity: 0.75;
  color: #ffc300 !important;
}

.sliderWrapper {
  margin-bottom: 50px;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 15px !important;
  line-height: 22px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #292929;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #ffc300 !important;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 1px solid transparent !important;
  outline: none;
  background: transparent;
  border-radius: 50% !important;
}

.slick-dots li.slick-active button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 1px solid #ffc300 !important;
  outline: none;
  background: transparent;
  border-radius: 50% !important;
}


.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.no-items{
  background: white;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.progress-bar {
  position: relative;
  height: 20px;
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 50px;
  overflow: hidden;
}

.filler {
  background: #22cc88;
  height: 100%;
  transition: width 0.2s ease-in;
}
