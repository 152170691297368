.cWIndowHolder {
  background: #000000;
  border-radius: 15px;
  overflow: hidden;
}
.cWIndow {
  background: #000000;
  border-radius: 15px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: #b8babb;
  width: 100%;
  height: 550px;
  padding: 20px;
  scrollbar-width: auto;
  scrollbar-color: white rgba(0, 0, 0, 0.2);
  overflow: auto;
}
.cWIndow::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
.cWIndow::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: white;
}


@media (max-width: 767px) {
  .cWIndow {
    height: 300px;
    padding: 10px;
    font-size: 14px;
  }
}
