.wnHead {
    min-height: 60px;
    padding: 20px 30px;
    /* border-bottom: 1px solid rgba(152, 152, 152, 0.2); */
}
.wnHeading {
    font-size: 20px;
    line-height: 140%;
    color: #4a4a4a;
    margin: 0;
    font-weight: 700;
}
.wnBody {
    padding: 20px 30px;
}
.sessionRevSec {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid rgba(152, 152, 152, 0.2);
}
.sessionRevSec:last-child {
    border-bottom: 0;
}
.revLeft {
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
}
.revRight {
    padding-top: 45px;
}
.checkedCircle {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-right: 20px;
}
.wnSUbHead {
    font-size: 18px;
    line-height: 140%;
    color: #4a4a4a;
    margin: 0;
    font-weight: 700;
    margin-bottom: 20px;
}
.wnDesc {
    font-size: 18px;
    line-height: 140%;
    color: #4a4a4a;
    margin: 0;
    font-weight: 400;
    margin-bottom: 10px;
}
.wnJason {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #66bb6a;
    margin: 0;
    word-break: break-all;
    white-space: normal;
}
.reviewSessionHolder {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .wnHeading {
        font-size: 18px;
    }
    .wnHead {
        min-height: 60px;
        padding: 15px 15px;
    }
    .wnBody {
        padding: 15px 15px;
    }
    .sessionRevSec {
        flex-direction: column;
    }
    .revRight {
        padding-top: 10px;
    }
    .wnDesc {
        font-size: 16px;
    }
    .wnJason {
        font-size: 14px;
    }
    .checkedCircle {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    .wnSUbHead {
        margin-bottom: 10px;
        font-size: 16px;
    }
}
