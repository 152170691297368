

.conMatrixWrap {
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 20px;
}
.conMatLeft {
    display: flex;
    align-items: center;
    justify-content: center;
}
.conLeftHead {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    line-height: 120%;
    position: relative;
    top: -30px;
    margin-right: 5px;
}
.conMatMiddle {
    margin: 0 0;
}
.conMidTop {
    text-align: center;
    margin-bottom: 10px;
}
.conMidTop span {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    position: relative;
    left: 50px;

}
.conMidBoxWrap {
    margin-bottom: 0;
}
.cbRow {
    display: flex;
    align-items: stretch;

}
.cbCell {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cbCell span {
    color: #fff;
    font-size: 16px;
}
.cbCell.firstCell {
    align-items: center;
    justify-content: flex-end;
}
.cbCell.firstCell span {
    color: #000000;
    font-size: 16px;
}
.cbRow.lastRow .cbCell {
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    line-height: 120%;
    position: relative;
    top: -5px;

}
.cbRow.lastRow .cbCell span {
    color: #000000;
    font-size: 16px;
}
.conMidBottom {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    position: relative;
    left: 50px;
    margin-top: -40px;
}
.conMatRight {
    margin-left: 20px;
    display: flex;
}
.conMatIndexBar {
    width: 10px;

    background-image: linear-gradient(#f7c82e, #08205C);
    position: relative;
    top: 35px;
}
.conIndValWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;
    top: 40px;
}
.conIndValWrap span {
    color: #000000;
    font-size: 14px;
    text-align: left;
}

@media (max-width: 991px) {
    .cbCell {
        width: 70px;
        height: 70px;
    }
    .conMatIndexBar {
        height: 280px;
    }
    .conIndValWrap {
        height: 280px;
    }
    .cbCell.firstCell span {
        font-size: 14px;
    }
    .cbCell.firstCell {
        width: 75px;
    }
    .conMidBottom {
        margin-top: -20px;
    }
}
