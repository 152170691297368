.headeInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 0 20px;
  flex-grow: 1;
}
.searchWrapper {
  position: relative;
}
.searchInp {
  width: 300px;
  height: 45px;
  background: #ffffff33;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #fff;
  padding: 10px 35px 10px 10px;
}
.searchInp::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.searchInp:focus {
  outline: none;
}
.searchIcon {
  position: absolute;
  width: 20px;
  height: 20px;
  object-fit: contain;
  right: 10px;
  top: calc(50% - 10px);
  cursor: pointer;
}
.headerRight {
  display: flex;
  align-items: center;
}
.notifWrap {
  margin-right: 20px;
}
.notifLink .bellIcon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.profNameWrap {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.profImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px;
  overflow: hidden;
}
.profImg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px;
}
.profName {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.headerLeft {
  display: flex;
  align-items: center;
}
.smMenuCaller {
  margin-left: 15px;
  display: none;
}
.smMenuCaller img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .smMenuCaller {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .searchInp {
    width: 170px;
    height: 45px;
  }
  .notifLink .bellIcon {
    width: 25px;
    height: 25px;
  }
  .profImg {
    width: 40px;
    height: 40px;
    margin-right: 0;
  }
  .profImg img {
    width: 40px;
    height: 40px;
  }
  .profName {
    display: none;
  }
  .headeInner {
    height: 80px;
  }
}
