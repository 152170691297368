.wizardWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
.wizardItem {
    width: 170px;
    background: #ffffff;
    border: 1px solid #c5c5c5;
    box-shadow: 4px 4px 5px -6px rgb(0 0 0 / 21%);
    border-radius: 12px;
    padding: 18px;
    margin-bottom: 30px;
}
.wizardItem.current {
    background-color: #ffac71 !important;
    border: 1px solid #ff6b00;
}
.wizardItem.current .wizardName {
    color: #fff;
}
.wizardItem.current .wStepName {
    color: #ff6b00;
}
.wizardItem.current .wizardStatus {
    background-color: #ff6b00;
    color: #ffffff;
}

.wizardItem.completed {
    background-color: #deffed !important;
    border: 1px solid #27bf69;
}
.wizardItem.completed .wizardName {
    color: #27bf69;
}
.wizardItem.completed .wStepName {
    color: #27bf69;
}
.wizardItem.completed .wizardStatus {
    background-color: #27bf69;
    color: #ffffff;
}

.wizardItem.failed {
    background-color: #c69393 !important;
    border: 1px solid #d60c0c;
}
.wizardItem.failed .wizardName {
    color: #ffffff;
}
.wizardItem.failed .wStepName {
    color: #d60c0c;
}
.wizardItem.failed .wizardStatus {
    background-color: #d60c0c;
    color: #ffffff;
}

.wizardItem:last-child {
    margin-bottom: 0;
}
.wiHead {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}
.wHdIcon {
    width: 25px;
    height: 25px;
    object-fit: contain;
    margin-right: 10px;
}
.wStepName {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.045em;
    color: #989898;
}
.wizardName {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.045em;
    color: #2f2e2e;
    margin-bottom: 10px;
    margin-top: 0;
}
.wizardStatus {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 130%;
    letter-spacing: 0.045em;
    color: #ffffff;
    padding: 3px 10px;
    border-radius: 6px;
    background: #989898;
}
.wizardBulletWrap {
    margin-right: 20px;
    margin-top: -7.5px;
}
.wBulletItem {
    width: 3px;
    height: 180px;
    position: relative;
    background-color: #b2b2b2;
}
.wBulletItem.current {
    background-color: #ff6b00;
}
.wBulletItem.current:before {
    content: "";
    background-color: #ff6b00;
}
.wBulletItem.completed {
    background-color: #27bf69;
}
.wBulletItem.completed:before {
    content: "";
    background-color: #27bf69;
}

.wBulletItem.failed {
    background-color: #d60c0c;
}
.wBulletItem.failed:before {
    content: "";
    background-color: #d60c0c;
}
.wBulletItem:last-child {
    height: 0;
    width: 0;
}
.wBulletItem::before {
    content: "";
    position: absolute;
    top: 0;
    left: -6px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #b2b2b2;
}

@media (max-width: 2000px) {
    .wizardWrap {
        flex-direction: column;
    }
    .wizardItemWrap {
        display: flex;
        align-items: start;
        justify-content: center;
        margin-left: -5px;
        margin-right: -5px;
    }
    .wizardItem {
        width: 140px;
        padding: 7px;
        margin: 0 5px;
    }
    .wHdIcon {
        width: 15px;
        height: 15px;
    }
    .wizardName {
        font-size: 14px;
        margin-bottom: 0
    }
    .wizardBulletWrap {
        display: flex;
        margin-top: 0;
        margin-bottom: 30px;
        width: 100%;
        justify-content: center;
    }
    .wBulletItem {
        width: 150px;
        height: 3px;
    }
    .wBulletItem::before {
        top: -6px;
        left: -2px;
        width: 15px;
        height: 15px;
    }
}

@media (max-width: 950px) {
  .wBulletItem {
      width: 90px;
      height: 3px;
  }
    .wizardBulletWrap {
        margin-bottom: 10px;
        margin-right: 0;
    }
    .wBulletItem::before {
        top: -5px;
        left: -2px;
        width: 12px;
        height: 12px;
    }
    .wizardItem {
        width: 70px;
        padding: 2px;
        margin: 0 2px;
    }
    .wiHead {
        margin-bottom: 0;
    }
    .wHdIcon {
        width: 13px;
        height: 13px;
    }
    .wStepName {
        font-size: 11px;
        line-height: 130%;
    }
    .wizardName {
        display: none;
    }
    .wizardStatus {
        display: none;
    }
}
