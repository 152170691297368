.wizardHolderWrap {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 20px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}

.wizardStepWrap {
  background: rgba(217, 217, 217, 0.19);
  border: 1px solid rgba(152, 152, 152, 0.21);
  border-radius: 15px;
  width: 250px;
  padding: 15px;
}
.wizardBodyWrap {
  border: 1px solid rgba(152, 152, 152, 0.21);
  border-radius: 8px;
  background-color: #fff;
  width: calc(100% - 270px);
}

@media (max-width: 2000px) {
  .wizardStepWrap {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
  }
  .wizardBodyWrap {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .wizardHolderWrap {
    padding: 10px 10px;
  }
  .wizardStepWrap {
    padding: 15px;
    margin-bottom: 10px;
  }
}
