.dashCardWrap {
  background: #ffffff;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px 20px;
  min-height: 300px;
}

.statusCard {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 30px;
  padding: 5px 15px;
}
.statusCard.yellow {
  background-color: rgba(229, 138, 0, 0.27);
  color: #c27400;
}
.statusCard.green {
  background-color: rgba(39, 191, 105, 0.27);
  color: #27bf69;
}
.statusCard.grey {
  background-color: #d9d9d9;
  color: #737272;
}
.statusCard.red {
  background-color: rgba(222, 0, 0, 0.27);
  color: #e70000;
}

@media (max-width: 768px) {
  .dashCardWrap {
    min-height: 220px;
  }
}
