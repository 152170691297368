a{text-decoration: none}
.app {margin:50px 300px;}
.grid-two{display: grid;grid-template-columns:1fr 2fr;column-gap: 2rem;row-gap:2rem;}
.grid-six {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap:20px;
  row-gap:5px;
}

.file:hover{cursor: pointer;}
.file{font-size: 20px;margin-bottom:0px}
.file-active{color:blue}

.btn{padding:7px 15px;border-radius: 5px; font-weight: 600}
.btn:hover{cursor: pointer;}
.btn-blue{color:white; background-color: blue}
.btn-green{color:white; background-color: green}
.btn-red{color:white; background-color:red}
.btn-grey{color:white; background-color:grey}


.u-margin-right-5{margin-right: 5px}
.u-margin-right-10{margin-right: 10px}
.u-margin-right-15{margin-right: 15px}
.u-margin-right-20{margin-right: 20px}

.u-margin-bottom-50{margin-bottom: 50px}

th{padding:15px;background-color: #e9e9e9}
.even{background-color: #fafafa}
.odd{background-color: white}

table{
  border-spacing: 0;
  border-collapse: collapse;
  width:100%
}
tr{text-align: center;}
tr:hover{cursor: pointer;opacity:.5}

td{padding:10px;font-weight: 500}


.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}



/* style for the upload audio component */
.upload-audio {
  width: 100%;
  margin-top: 5vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
}

.upload-audio i.audio-icon {
  font-size: 5em;
}

/* the container where wavesurfer adds the audio waveform */
.waveform-container {
  margin: 0 auto;
  max-width: 80vw;
}

/* style for the navbar */
nav {
  display: flex;
  align-items: center;
  height: 3.5em;
  padding: 0 2em;
  background-color: #1D0B22;
  color: white;
  font-size: large;
  font-weight: bold;
}

nav>.brand {
  display: flex;
  color: white;
  align-items: center;
}

nav a {
  text-decoration: none;
  color: inherit;
}

/* all icons */
i.material-icons {
  font-size: 1.5em;
  color: #1D0B22;
}

h1 {
  color: #1D0B22
}

/* style for the upload button */
button.upload-btn {
  width: 10em;
  font-size: 1em;
  padding: 0.5em;
  background: #5B266B;
  border-radius: 999px;
  color: white;
  border-color: #5B266B;
  cursor: pointer;
}

button.upload-btn:hover {
  filter: brightness(1.1);
}

/* style for the trim button */
.trim {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #1D0B22;
  color: white;
  width: 5em;
  border-radius: 2px;
  cursor: pointer;
  padding: 0.25em;
  border: 1px solid rebeccapurple;
}

/* all the button controls */
div.all-controls {
  margin: 2em auto;
  max-width: 60vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.all-controls .right-container,
.all-controls .left-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
}

button.controls {
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1.2em;
}

/* style for both sliders */
.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: rgba(213, 184, 255, 0.7);
  border: 1px solid #1D0B22;
  border-radius: 999px;
  margin: 0;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  filter: brightness(1.05);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #1D0B22;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #1D0B22;
  cursor: pointer;
  border-radius: 50%;
}

.volume-slide-container {
  display: flex;
  align-items: center;
}

/* smaller icon for zoom slider */
i.zoom-icon {
  font-size: 0.8em;
}

/* toggle switch style*/
.switch {
  position: relative;
  display: inline-block;
  width: 4em;
  height: 1.2em;
}

.switch input {
  display: none;
}

.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1D0B22;
  -webkit-transition: .2s;
  transition: .2s;
  padding: 0;
  border-radius: 999px;
}

.toggle:before {
  position: absolute;
  content: "";
  top: -10%;
  height: 1.30em;
  width: 1.30em;
  background-color: #923CAC;
  border: 0.05em solid rebeccapurple;
  -webkit-transition: .2s;
  transition: .2s;
  border-radius: 50%;
}

input:checked+.toggle:before {
  -webkit-transform: translateX(180%);
  -ms-transform: translateX(180%);
  transform: translateX(180%);
}

.on {
  display: none;
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 45%;
  left: 35%;
  font-size: 0.5em;
}

.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 45%;
  left: 65%;
  font-size: 0.5em;

}

input:checked+.toggle .on {
  display: block;
}

input:checked+.toggle .off {
  display: none;
}

/* footer style */
footer {
  font-family: inherit;
  position: fixed;
  bottom: 2%;
  right: 2%;
}

@media screen and (max-width: 430px) {
  nav {
    font-size: 1em;
  }

  .upload-audio i.audio-icon {
    font-size: 4em;
  }

  /* all icons */
  i.material-icons {
    font-size: 1.5em;
  }

  h1 {
    font-size: 1.5em;
  }

  div.all-controls {
    max-width: 90vw;
    flex-flow: column nowrap;
  }

  .waveform-container {
    max-width: 90vw;
  }

  .all-controls .right-container,
  .all-controls .left-container {
    justify-content: space-evenly;
    width: 100%;
    margin-top: 1em;
  }

  /* smaller icon for zoom slider */
  i.zoom-icon {
    font-size: 1em;
  }

}
