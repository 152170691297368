.topLogo {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidenavTop {
  height: calc(100vh - 450px);
  border-bottom: 1px solid #ddd;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2);
}
.sidenavTop::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
.sidenavTop::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}
.sidenavBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 10px 20px;
}
.navItem {
  margin-bottom: 10px;
}
a.navItemLink {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 10px;
  border-radius: 6px;
  text-decoration: none;
  color: #687582;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.2s ease;
}
.navItemLink:hover {
  background: rgba(26, 80, 186, 0.06);
  color: #1a50ba;
}
.navItemLink.activeGroup:hover {
  background: rgba(26, 80, 186, 0.06);
  color: #1a50ba;
}
.navItemLink:hover .topSImg {
  filter: brightness(1) opacity(1);
}
.navItemLink.active .topSImg {
  filter: brightness(1) opacity(1);
}
.navItemLink.activeGroup .topSImg {
  filter: brightness(1) opacity(1);
}
.navItemLink.navBtmLink {
  font-weight: 400;
  color: #000000;
}
.navLogoutLink {
  background: rgba(152, 152, 152, 0.08);
}
.navLogoutLink span {
  color: #989898;
}
.navItemLink svg {
  fill: #687582;
  width: 25px;
}
.navItemLink.active {
  background: rgba(26, 80, 186, 0.06);
  color: #1a50ba;
}
.navItemLink.activeGroup {
  background: transparent;
  color: #1a50ba;
}
.navItemLink img {
  width: 23px;
  margin-right: 7px;
  height: 23px;
  object-fit: contain;
}
.topSImg {
  filter: brightness(0.4) opacity(0.6);
  transition: all 0.2s ease;
}
.logoImg {
  width: 212px;
}
.sidebarIn {
  padding: 0 10px 10px;
}
.sidenavHeadiung {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #989898;
}
.footerLogo {
  width: 80%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.navChildren {
  padding-top: 10px;
}
.navItemLink.navChildItem {
  padding-left: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
}
