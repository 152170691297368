.editor {
  counter-reset: line;
  border: 1px solid #e0e3e6;
  border-radius: 5px;
  color: #090 !important;
}
.codeEditorWrap {
  position: relative;
  /* min-height: 300px; */
}

.editor #codeArea {
  outline: none;
  padding-left: 60px !important;
}

.editor pre {
  padding-left: 60px !important;
}

.editor .editorLineNumber {
  position: absolute;
  left: 0px;
  color: #cccccc;
  text-align: right;
  width: 40px;
  font-weight: 100;
}

.greenText {
  color: #090 !important;
}
