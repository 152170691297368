.setupCardContent {
  width: 100%;
  padding: 10px 0;
}
.activeCardContent {
  width: 100%;
}
.activeCardHead {
  width: 100%;
  background-color: #e2e2e2;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.activeCardHeadBox {
  width: 33.33%;
  text-align: center;
}
.activeCardHeadBox h5 {
  font-size: 16px;
  color: #2f2e2e;
  font-weight: 700;
  line-height: 120%;
  margin: 0 auto;
}
.activeCardBody {
  width: 100%;
  padding: 0 40px;
}
.activeCardBodyBox {
  width: 33.33%;
  padding: 15px 5px;
}
.activeCardBodyBox h6 {
  font-size: 16px;
  font-weight: 400;
  color: #2f2e2e;
  margin: 0;
}
.activeCardBodyBox button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.buttonIconBox {
  width: 35px;
  height: 35px;
  padding: 5px;
  margin: 0 5px;
}
.buttonIconBox img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}
.activeCardBodyIconBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeCardRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.databaseFillTextArea {
  width: 100%;
  max-height: 200px;
  min-height: 200px;
  background: #ffffff;
  border: 0.5px solid rgba(152, 152, 152, 0.74);
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px 40px;
  color: #2f2e2e;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  font-family: "Open Sans Hebrew";
}
.databaseFillTextArea:focus {
  outline: transparent;
  border: 0.3px solid rgba(26, 80, 186, 1);
}

.modalPlayDiv {
  width: 100%;
  text-align: center;
}
.modalPlayDiv img {
  height: 90px;
  margin: 40px auto;
  display: block;
}
.modalPlayDiv span {
  font-size: 15px;
  color: #2f2e2e;
  font-weight: 400;
  display: block;
  margin: 0 auto;
  width: 100%;
}
.modalPlayDiv h3 {
  font-size: 60px;
  color: #2f2e2e;
  font-weight: 400;
  display: block;
  margin: 20px auto 0;
  width: 100%;
}
.collectTableBody {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2);
}
.collectTableBody::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}
.collectTableBody::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .activeCardBody {
    padding: 0 15px;
  }
  .activeCardHead {
    padding: 20px 10px;
  }
  .buttonIconBox {
    width: 26px;
    height: 26px;
  }
  .buttonIconBox img {
    width: 20px;
    height: 20px;
  }
  .cardWhiteBodyWrap {
    min-height: auto;
  }
}
